export interface UserInteraction {
    id: string
    count: number
    value: string
    updateAt: string
}

export interface UserInteractions {
    user: string
    interactions: UserInteraction[]
}

interface SetInteractionBody {
    category: string
    value: any
    count?: number
}

export interface SetInteractionRequest {
    body: SetInteractionBody
}

export enum userInteractionsType {
    LANDING_FIRST_VISIT = 'LANDING_FIRST_VISIT',
    TOOLTIP_PUSHING_LIMITS_MULTIANTENNA = 'TOOLTIP_PUSHING_LIMITS_MULTIANTENNA',
    TOOLTIP_SUGGESTED_VERSION = 'TOOLTIP_NNS10_SUGGESTED_DESIGN',
    TOOLTIP_NEW_DESIGN = 'TOOLTIP_NEW_DESIGN',
    NPS_SURVEY = 'NPS_SURVEY'
}