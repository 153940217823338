export const dateInMillisToFormattedString = (date: string | undefined, separator: string = '/'): string => {
  if (date) {
    let currentDate = new Date(date);
    let day = currentDate.getDate().toString();
    let month = (currentDate.getMonth() + 1).toString();
    let year = currentDate.getFullYear().toString();
    return day.padStart(2, '0') + separator + month.padStart(2, '0') + separator + year;
  } else {
    return '';
  }
}
export const dateInMillisToFormattedMonthString = (date: string | undefined, separator: string = ' '): string => {
  if (date) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
                    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];


    let currentDate = new Date(date);
    let day = currentDate.getDate().toString();
    let monthName = months[currentDate.getMonth() +1];
    let year = currentDate.getFullYear().toString();
    return day.padStart(2, '0') + separator + monthName.padStart(2, '0') + separator + year;
  } else {
    return '';
  }
}

export const dateInMillisToDate = (date: string | undefined): Date | null => {
  return date ? new Date(date) : null;
}

export const datetoFormattedTimestampString = (date: Date): string => {
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let year = date.getFullYear().toString();
  let hour = date.getHours().toString();
  let minute = date.getMinutes().toString();
  return day.padStart(2, '0') + "/" + month.padStart(2, '0') + "/" + year + " " + hour.padStart(2, '0') + ":" + minute.padStart(2, '0');
}

export const timePassedFromNow = (timestamp: string): string => {
  const now = Date.now(); // Current timestamp in milliseconds
  const diff = now - +timestamp; // Difference in milliseconds

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) return `${years} years ago`;
  if (months > 0) return `${months} months ago`;
  if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  return `${seconds} seconds ago`;
}

export const addDaysToDate = (days: number): number => {
  const today = new Date();
  today.setDate(today.getDate() + days)
  return today.getTime();
}