import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppState } from "@/store/store";
interface FeatureFlagsState {
  flags: Record<string, boolean>;
  triggerActions: Record<string, boolean>;
  loading: boolean;
  error: string | null;
}

const initialState: FeatureFlagsState = {
  flags: {},
  triggerActions: {
    'NPS_SURVEY': false,
  },
  loading: false,
  error: null,
};

// Slice
export const featureFlagsSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
      setAllFeatures(state, action: PayloadAction<Record<string, boolean>>) {
        state.flags = action.payload;
      },
      setTriggerAction(state, action: PayloadAction<{ feature: string; value: boolean }>) {
        const { feature, value } = action.payload;
        state.triggerActions[feature] = value;
      }
    }
  });
  
  export const { setAllFeatures, setTriggerAction } = featureFlagsSlice.actions;

  export const getAllFeatures = () => (state: AppState) => state.featureFlags.flags;

  export const isFeatureAvailable = (feature: string) => (state: AppState) => state.featureFlags.flags[feature] === true;

  export const isTriggerActionAvailable = (feature: string) => (state: AppState) => state.featureFlags.triggerActions[feature] === true;

  export default featureFlagsSlice.reducer;