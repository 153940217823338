import '@aws-amplify/ui-react/styles.css';

import { ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import { Authenticator, useAuthenticator, translations } from '@aws-amplify/ui-react';
import { Amplify, Auth, Hub, I18n } from 'aws-amplify';
import { useI18nContext } from '@/i18n/i18n-react'
import { Locales } from '@/i18n/i18n-types';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@/store/hooks';
import { clearStore } from '@/store/slice/version';
import { getParsedJson } from '@/store/slice/parser';
import ToastContainer from '../ToastService';
import { pushMessage } from '@/store/slice/messages';
import SignInHeader from './SignInHeader';
import SignInFooter from './SignInFooter';
import SignUpFormFields from './SignUpFormFields';
import { navigateToExternalUrl } from '@/lib/utils';
import { VideoAurora } from '../common/VideoAurora';
import Link from 'next/link';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import DefaultFooter from './DefaultFooter';
import { getErrorFromSignUp } from '@/lib/validation';
import clsx from 'clsx';
import Lottie from 'react-lottie-player'
import lottieJson from '../../../public/bar-footer-red.json'

I18n.setLanguage('en');
I18n.putVocabularies(translations);
I18n.putVocabularies({
  en: {
  'Your code is on the way. To log in, enter the code we emailed to': 'Your code is sent to your mailbox. To log in, enter the code we emailed to',
  'Your code is on the way. To log in, enter the code we sent you': 'Your code is sent to your mailbox. To log in, enter the code we sent you',
  'Your code is on the way. To log in, enter the code we texted to': 'Your code is sent to your phone. To log in, enter the code we texted to',
  Confirm: 'Confirm Code'
  },
});

Amplify.configure({
  aws_cognito_region: process.env.NEXT_PUBLIC_POOL_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_POOL_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  oauth: {
    domain: process.env.NEXT_PUBLIC_OAUTH_DOMAIN,
    scope: ['openid aws.cognito.signin.user.admin '],
    redirectSignIn: `${process.env.NEXT_PUBLIC_REDIRECT_SIGN_IN}dashboard`,
    redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_SIGN_OUT,
    responseType: 'token'
  }  
});

function scheduleQueryUserInfo(){
  return new Promise(() => {
    setTimeout(async () => {
      Auth.currentAuthenticatedUser({bypassCache: true}).then((info) => { 
        if (info?.attributes["custom:orgId"] === undefined)
          scheduleQueryUserInfo();            
      });
    }, 3000)
 });
}

type AppConfigProps = {
  children?: ReactNode
}

export default function AppAuthentication({ children }: Readonly<AppConfigProps>) {
  const { LL, setLocale } = useI18nContext()
  const { route, user } = useAuthenticator(context => [context.route, context.user]);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [givenEmail, setGivenEmail] = useState('');

  const { removeItem } = useLocalStorage('OxionStorage')

  const getAuthenticatorInitialState = (asPath:string) : "resetPassword" | "signIn" | "signUp" | undefined => {
    if (asPath === '/signup') {
      return 'signUp'
    }

    if (asPath === '/forgot-password') {
      return 'resetPassword'
    }

    return 'signIn'
  }

  const isPathOpened = (): boolean => {
    return (router.pathname === '/') || (router.pathname === '/cookies-policy') || (router.pathname === '/created-by-ignion')
  }

  const initialState = getAuthenticatorInitialState(router.pathname)
  
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signOut') {
        dispatch(clearStore());
        removeItem()
      }else if (event === 'signIn') {
        dispatch(getParsedJson())  
      }
    });

    let orgId = user?.attributes?.['custom:orgId'] ?? '';
    if (orgId === '') {
      scheduleQueryUserInfo();  
    }
  }, []);

  useEffect(() => {
    
    if (user?.attributes?.locale !== undefined)
      setLocale(user.attributes.locale as Locales);
  }, [user]);

  useLayoutEffect(() => {
    const scaleAurora = () => {
      
      const auroraWrapper = document.querySelector('[data-amplify-authenticator]') as HTMLElement;
      const auroraCorporate = document.querySelector('.background-video-corporate') as HTMLElement;
      
      const auroraContent = document.querySelector('[data-amplify-authenticator] [data-amplify-router] > div') as HTMLElement;

        if (auroraCorporate && auroraContent) {

          if (auroraWrapper.offsetHeight < auroraContent.offsetHeight) {
            auroraCorporate.style.height = `${auroraContent.offsetHeight}px`;
          } else {
            auroraCorporate.style.height = `calc(100% - 60px)`;
          }
          
        }
    }

    window.addEventListener('resize', scaleAurora);
    scaleAurora()
    return () => {
        window.removeEventListener('resize', scaleAurora);
      };
  }, []);

  if ((route === 'authenticated') && ((router.asPath === '/signup') || (router.asPath === '/signin') || (router.asPath === '/signin-corp') || (router.asPath === '/forgot-password')))
  {
    router.push(`/dashboard`, undefined, { shallow: true })
  } else if ((route === 'authenticated') || isPathOpened()) {
      return children;
  } else if (router.pathname === '/404') {
    return children;
  }

  const renderDefaultHeader = (header: string) => {
    return <h2 className="Oxion-h2">{header || LL.signup.header()}</h2>
  }

  const confirmPasswordHeader = () => {
    
    return (
      <div className='flex flex-column justify-content-center align-items-center'>
        <div>
          <h2>{LL.confirmResetPassword.header()}</h2>
          <span className='Oxion-small-regular' style={{display: 'block', marginBottom: '1rem', color: '#FFFF'}}>{LL.confirmResetPassword.intro({email: givenEmail ?? ""})}</span>
        </div>
      </div>
    )
  }

  
  const components = {
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    SignUp: {
      Header() { return renderDefaultHeader(LL.signup.header())},
      FormFields() { return <SignUpFormFields />},
      Footer() { return <DefaultFooter uniqueId='Signup' /> }
    },
    ConfirmSignUp: {
      Footer() { return <DefaultFooter uniqueId='ConfirmSignup' /> }
    },
    ResetPassword: {
      Header() { return renderDefaultHeader(LL.resetPassword.header())},                
      Footer() { return <DefaultFooter uniqueId='ResetPassword' /> }
    },
    ConfirmResetPassword: {
      Header() { return confirmPasswordHeader()},
      Footer() { return <DefaultFooter uniqueId='ConfirmReset' /> }
    },
  };

  const formFields = {
    signIn: {
      username: {
        label: LL.signin.usernameLabel(),
        placeholder: LL.signin.usernamePlaceholder(),
        type: 'email',
      },
    },
    signUp: {
      given_name: {
        label: LL.signup.givenNameLabel(),
        placeholder: LL.signup.givenNamePlaceholder(),
        isRequired: true,
        order: 1,
      },
      family_name: {
        label: LL.signup.familyNameLabel(),
        placeholder: LL.signup.familyNamePlaceholder(),
        isRequired: true,
        order: 2,
      },
      email: {
        label: LL.signup.usernameLabel(),
        placeholder: LL.signup.usernamePlaceholder(),
        isRequired: true,
        order: 3,
      },
      password: {
        label: LL.signup.passwordLabel(),
        placeholder: LL.signup.passwordPlaceholder(),
        isRequired: true,
        order: 5,
      },
      confirm_password: {
        label: LL.signup.confirmPasswordLabel(),
        placeholder: LL.signup.confirmPasswordPlaceholder(),
        isRequired: true,
        order: 6,
      },
      "custom:companyName": {
        label: LL.signup.companyLabel(),
        placeholder: LL.signup.companyPlaceholder(),
        isRequired: true,
        order: 4,
      },
    },
    resetPassword: {
      username: {
        label: LL.resetPassword.usernameLabel(),
        placeholder: LL.resetPassword.usernamePlaceholder(),
        isRequired: true,
        type: 'email',
      }
    },
    forceNewPassword: {
      password: {
        label: LL.forceNewPassword.passwordLabel(),
        placeholder: LL.forceNewPassword.passwordPlaceholder(),
        isRequired: true,
        order: 2,
      },
      confirm_password: {
        label: LL.forceNewPassword.confirmPasswordLabel(),
        placeholder: LL.forceNewPassword.confirmPasswordPlaceholder(),
        isRequired: true,
        order: 3,
      },   
    },
    forgotPassword: {
      username: {
        label: LL.forgotPassword.usernameLabel(),
        placeholder: LL.forgotPassword.usernamePlaceholder(),        
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        label: LL.confirmResetPassword.codeLabel(),
        placeholder: LL.confirmResetPassword.codePlaceholder(),
        isRequired: true,
        order: 1,
      },
      password: {
        label: LL.confirmResetPassword.passwordLabel(),
        placeholder: LL.confirmResetPassword.passwordPlaceholder(),
        isRequired: true,
        order: 2,
      },
      confirm_password: {
        label: LL.confirmResetPassword.confirmPasswordLabel(),
        placeholder: LL.confirmResetPassword.confirmPasswordPlaceholder(),
        isRequired: true,
        order: 3,
      },      
    },
    confirmSignIn: {
      confirmation_code: {
        label: LL.confirmSignIn.codeLabel(),
        placeholder: LL.confirmSignIn.codePlaceholder(),
        isRequired: true,
      },
    },
  };

  const isCorporateLogin:boolean =  (router.asPath === '/signin-corp')
  const routeLogoIgnion:string = isCorporateLogin ? '/images/logo/created_ignion.svg' : '/images/powered_ignion.svg'

  return (
    <div className={clsx('flex flex-row login-container', router.asPath === '/signin-corp' && 'login-container-corp')} style={{ width: '100vw', height: '100vh' }}>
      <ToastContainer />
      {
        isCorporateLogin ? null : <div className='background-video'>
                        <VideoAurora />
                      </div>
      }
      <div className='authenticator-container flex'>
        <div className='authenticator-information'>
          {
              isCorporateLogin ? <div className="lottie">
              <Lottie loop animationData={lottieJson} play />
            </div> : null
          }
          <div className='logo'>
            <Link href="/">
              <img className="logo_light" src="/images/logo/oxion_light.svg" alt='Oxion' />
            </Link>
          </div>
          <div className='claim'><h1 className='Oxion-Display1'>
            {
              isCorporateLogin ? <>Your next successful product <strong>begins here.</strong></> : 'The best antenna design tool for your next IoT project'
            }
            </h1></div>
          <div className='ignion'>
            <button onClick={() => navigateToExternalUrl('https://ignion.io/')}>
              <img className="logo_light" src={routeLogoIgnion} alt='Created by ignion' />
            </button>
          </div>
        </div>
        <div className='authenticator-form'>
          <div className="authenticator-form-container">
            {
              isCorporateLogin ? <div className='background-video background-video-corporate'>
                              <VideoAurora />
                            </div>
                            : null
            }
            <Authenticator initialState={initialState} loginMechanisms={['email']} formFields={formFields} components={components} 
                  services={{
                    async validateCustomSignUp(formData) {
                      let error:any = {};

                      const messageError = getErrorFromSignUp(formData, LL)
                      if (messageError !== '') {
                        error.acknowledgement = messageError
                      }
                      
                      Object.keys(formData).forEach(key => {
                        if (key !== 'acknowledgement' && key !== 'consentdata' && 
                            (typeof formData[key] === 'string' || formData[key] instanceof String) && 
                            formData[key].length > 0 && 
                            formData[key].trim() === '') {
                          error[key] = LL.signup.invalidValue();
                        }
                      });
                      
                      if (Object.keys(error).length > 0) return error;
                    },
                    async handleSignUp({ username, password, attributes, }) {
                      Object.keys(attributes).forEach(k => attributes[k] = attributes[k].trim());
                      let p = Auth.signUp({ username:username.trim(), password, attributes, autoSignIn: {
                        enabled: true
                      }}) 
                      p.catch((e) => {
                        dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                      });

                      return p
                    },
                    async handleConfirmSignUp({ username, code, }) {
                      let p = Auth.confirmSignUp(username, code) 
                      p.catch((e) => {
                        dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                      });

                      return p
                    },
                    async handleSignIn({ username, password, }) {
                      let p = Auth.signIn(username, password) 
                      p.catch((e) => {
                        dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                      });

                      return p
                    },
                    async handleConfirmSignIn({ user, code, mfaType, }) {
                      let p = Auth.confirmSignIn(user, code, mfaType as "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | null | undefined) 
                      p.catch((e) => {
                        dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                      });

                      return p
                    },
                    async handleForgotPassword(formData) {
                      setGivenEmail(formData);
                      let p = Auth.forgotPassword(formData) 
                      p.catch((e) => {
                        dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                      });

                      return p
                    },
                    async handleForgotPasswordSubmit({ username, code, password, }) {
                      let p = Auth.forgotPasswordSubmit(username, code, password)
                      p.catch((e) => {
                        dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                      });

                      return p
                    },

                  }}
              />
            </div>
          </div>          
      </div>
    </div>
  );
} 