import clsx from "clsx";
import { FC, SyntheticEvent } from "react"

export type RadioButtonIconProps = {
    handleClick: (event: SyntheticEvent<Element, Event>) => void;
    active?: boolean
}

const RadioButtonIcon: FC<RadioButtonIconProps> = ({ handleClick, active = false }) => {     
    return (
        <button aria-label="RadioButtonIcon" className='radioButtonIcon' onClick={(e) => handleClick(e)}>
            <div className={clsx('icon-active', active && 'show')}></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <rect x="13" y="13" width="14" height="14" rx="7" stroke="#0000FF" strokeWidth="2"/>
            </svg>               
        </button>       
    )
}


export default RadioButtonIcon