import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Auth } from 'aws-amplify';


const rawBaseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_IGNION_API,
  prepareHeaders: async (headers) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    headers.set('Authorization', `Bearer ${token}`);    
    
    headers.set('accept', 'application/json')
    return headers
  }
})

export const baseApi = createApi({
  baseQuery: rawBaseQuery,
  tagTypes: ['Project', 'ProjectFiles', 'ProjectSummary', 'Messages', 'NNS10Services', 'NNS10Service', 'Interactions', 'featureFlags'],
  endpoints: (builder) => ({}),
})
