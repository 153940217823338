import { EntityNotificationMessageData, Message } from "@/types/response/messages";
import { useGetUsersQuery } from "@/store/api/ignion/user";
import { useI18nContext } from "@/i18n/i18n-react";
import { useRouter } from "next/router";
import { useGetProjectQuery } from "@/store/api/ignion/project";
import { useSetMessageReadMutation } from "@/store/api/ignion/message";
import NotificationMessage from "../NotificationMessage";
import LinkButton from "@/components/common/LinkButton";

type ProjectUpdatedNotificationProps = {
  notification: Message<EntityNotificationMessageData>;
};

export default function ProjectUpdatedNotification({ notification }: Readonly<ProjectUpdatedNotificationProps>) {
  const { LL } = useI18nContext();
  const router = useRouter();

  const project = useGetProjectQuery({ projectId: notification.data.entityId, orgId: notification.data.orgId, idType: [0] });  
  const user = useGetUsersQuery({ emails: [notification.data.user ?? ""] }, { skip: notification.data.user === undefined }).data?.[0];

  const [setMessageRead] = useSetMessageReadMutation();

  return (
    <NotificationMessage notification={notification} user={user}>
      <div className="notification-text">
        <span className="notification-text-highlight">{user?.name ?? notification.data.user} </span> 
        <span>{LL.message.notification.projectUpdated()}</span>
        {project.isSuccess && <LinkButton label={project.data?.name} handleClick={() => {
          setMessageRead({ messageId: notification.id });
          router.push(`/organization/${notification.data.orgId}/project/${notification.data.entityId}`, undefined, { shallow: true })}} />}
      </div>
    </NotificationMessage>
  )
}