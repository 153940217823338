import { FC, ReactNode, SyntheticEvent, useRef } from "react"
import clsx from "clsx";
import { iconSideButton, styleMainButton } from "@/types/common";
import { Tooltip } from "primereact/tooltip";


export type MainButtonProps = {
    id?: string
    label: string
    disabled?: boolean
    selected?: boolean
    icon?: boolean
    iconSide?: iconSideButton
    style?: styleMainButton
    tooltipLabel?: string
    handleClick: (event: SyntheticEvent<Element, Event>) => void;
    children?: ReactNode
}

const MainButton: FC<MainButtonProps> = ({ id = '', label, handleClick, disabled = false, selected = false, icon = false, iconSide, style = styleMainButton.PRIMARY, tooltipLabel = '', children }) => { 

    const tooltipRef = useRef<Tooltip | null>(null);
    const stringWithoutSpaces:string = tooltipLabel?.split(' ').join('') ?? ''
    const stringWithoutPointsAndCommas = stringWithoutSpaces.replace(/[.,]/g, '');
    
    return (
        <>
            <div data-testid={style} className={clsx('main-button',
                style === styleMainButton.PRIMARY && 'primary-button',
                style === styleMainButton.SECONDARY && 'secondary-button',
                style === styleMainButton.TERCIARY && 'terciary-button',
                selected && 'selected',
                disabled && 'disabled',
                tooltipLabel !== '' && `tooltip main-button-${stringWithoutPointsAndCommas}`
            )}>
            <button id={id} className={id} onClick={(e) => handleClick(e)}>
                { icon && iconSide === iconSideButton.LEFT && children && children }
                <span>{label}</span>
                { icon && iconSide === iconSideButton.RIGHT && children && children }
            </button>
            </div>       
            <Tooltip position="bottom" ref={tooltipRef} target={`.main-button-${stringWithoutPointsAndCommas}`}>{tooltipLabel}</Tooltip>
        </>
    )
}


export default MainButton