import { baseApi } from '@/store/api/ignion/_base'
import { SearchUsersResponse, User } from '@/types/response/user'
import { SearchUsersRequest, GetUsersRequest, GetResourcesByUserRequest, GetResourcesByGroupRequest } from '@/types/request/user'

export const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    searchUsers: builder.query<SearchUsersResponse, SearchUsersRequest>({
      query: ({ searchString }) => ({ url: `user/search?searchString=${searchString}` }),
    }),
    getUsers: builder.query<User[], GetUsersRequest>({
      query: ({ emails }) => ({ url: `users?emails=${emails.join(",")}` }),
    }),
    getResourcesByUser: builder.query<string[], GetResourcesByUserRequest>({
      query: ({ username }) => ({ url: `resources?username=${username}` }),
    }),
    getResourcesByGroup: builder.query<string[], GetResourcesByGroupRequest>({
      query: ({ subscription }) => ({ url: `resources?subscription=${subscription}` }),
    })
  })
})

export const {
  useSearchUsersQuery,
  useLazySearchUsersQuery,
  useGetUsersQuery,
  useGetResourcesByGroupQuery,
  useGetResourcesByUserQuery,
  useLazyGetResourcesByGroupQuery,
  useLazyGetResourcesByUserQuery
} = userApi
