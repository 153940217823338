import { formatDateToString } from '@/lib/utils';
import { baseApi } from '@/store/api/ignion/_base'
import { DeleteMessageRequest, GetMessagesRequest, SetMessageReadRequest } from '@/types/request/message'
import { Message, MessageData, MessageType } from '@/types/response/messages'
import { Page } from '@/types/response/types';

const dateOffset = (24*60*60*1000) * 90

export const messageApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAllMessagesBy: builder.query<Page<Message<MessageData>>, GetMessagesRequest>({
      query: ({type, limit, from}) => ({
        url: `message?type=${MessageType[type]}&from=${formatDateToString(from ?? new Date((new Date()).getTime() - dateOffset))}&limit=${limit}`,
        method: 'GET'
      }),
      providesTags: ['Messages'],       
    }),
    deleteMessage: builder.mutation<void, DeleteMessageRequest>({
      query: ({messageId}) => ({
        url: `message/${messageId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Messages']      
    }),
    setMessageRead: builder.mutation<void, SetMessageReadRequest>({
      query: ({messageId}) => ({
        url: `message/${messageId}/read`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Messages']
    })
  })
})

export const {
  useGetAllMessagesByQuery,
  useLazyGetAllMessagesByQuery,
  useDeleteMessageMutation,
  useSetMessageReadMutation,
} = messageApi


