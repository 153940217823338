import { FC, ReactNode, useEffect, useLayoutEffect, useState } from "react"
import { useI18nContext } from "@/i18n/i18n-react"
import clsx from "clsx"
import { usePathname } from "next/navigation"
import { blockScroll, unBlockScroll } from "@/lib/utils"
import { VideoAurora } from "@/components/common/VideoAurora"

type DisclaimerMobileProps = {
    children?: ReactNode
  }

const DisclaimerMobile: FC<DisclaimerMobileProps> = ({ children }) => {  
    
    const { LL } = useI18nContext()

    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false)
    const [loadedContent, setLoadedContent] = useState<boolean>(false)
    const pathname = usePathname()
    
    useLayoutEffect(() => {
        function setDisclaimerMobile() {
            if ((pathname !== '/') && (pathname !== '/cookies-policy') && (pathname !== '/created-by-ignion') && (pathname !== '/signin') && (pathname !== '/signin-corp') && (pathname !== '/signup')) {
                const hasToShow = (window.innerWidth <= 1024)
                setShowDisclaimer(hasToShow)

                if(hasToShow) {
                    blockScroll()
                }else{
                    unBlockScroll()
                }

                if (window.innerWidth > 1024) {
                    setLoadedContent(false)
                }
            }
        }
        window.addEventListener('resize', setDisclaimerMobile);
        setDisclaimerMobile();
        return () => window.removeEventListener('resize', setDisclaimerMobile);
    }, [pathname]);

    useEffect(() => {
        setLoadedContent(true)
    }, [showDisclaimer]);

    return (
        <>
        {
        showDisclaimer && 
            <div className="mobile-disclaimer">
                <div className='mobile-disclaimer-content'>
                <div className='disclaimer-logo'><img src="/images/logo/oxion_light.svg" alt='Oxion' /></div>
                <div className='disclaimer-texts'>
                    <span className={clsx('right-to-left', !loadedContent && 'off')}>{LL.disclaimer.line1()}</span>
                    <span className={clsx('left-to-right', !loadedContent && 'off')}>{LL.disclaimer.line2()}</span>
                    <span className={clsx('right-to-left', !loadedContent && 'off')}>{LL.disclaimer.line3()}</span>
                    <span className={clsx('left-to-right', !loadedContent && 'off')}>{LL.disclaimer.line4()}</span>
                    <div className={clsx('visitus', !loadedContent && 'off')}>
                    <p>{LL.disclaimer.visitus()}</p>
                    </div>
                </div>
                <div className='disclaimer-footer'><img src="/images/powered_ignion.svg" alt='Created by Ignion' /></div>
                </div>
                <div className='mobile-disclaimer-video'>
                <VideoAurora />
                </div>
            </div>
        }
        { children }
        </>
    )
}


export default DisclaimerMobile