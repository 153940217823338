export enum arrowDirectionButton {
    BACK_ARROW = 'BACK_ARROW',
    FORWARD_ARROW = 'FORWARD_ARROW'
}

export enum iconSideButton {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export enum iconSizeButton {
    REGULAR = 'REGULAR',
    SMALL = 'SMALL'
}


export enum styleMainButton {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERCIARY = 'TERCIARY'
}

export enum colorBranding {
    WHITE = '#FFFFFF',
    LIGHT_BLUE='#E8E9F2',
    DARK_BLUE='#01013A',
    BLUE='#0000FF',
    PINK='#FF9BFF',
    SOFT_PURPLE='#B76CFF',
    DARK_PURPLE='#530E90',
    CYAN='#57DFFF',
    GREEN='#9CFFBE'
}