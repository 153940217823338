import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from "@/store/store";
import { ToastMessage } from "primereact/toast";


type MessageQueue = {
  messages: ToastMessage[]  
}

const messages: ToastMessage[]  = []; 
const initialState = {
  messages: messages
};

// Slice
export const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    pushMessage: (state, action: PayloadAction<ToastMessage>) => {
      switch (action.payload.severity) {
        case 'error':
          action.payload.icon = 'ms ms-cancel';
          break;
        case 'warn':
          action.payload.icon = 'ms ms-error';
          break;
        case 'info':
          action.payload.icon = 'ms ms-info';
          break;
        case 'success':
          action.payload.icon = 'ms ms-check-circle';
          break;
        default:
          break;
      }
      
      // @ts-ignore
      state.messages.push(action.payload);
    },
    clearMessages: (state) => {
      state.messages = [];
    }
  },
  
})

export const selectMessages = (state: AppState): MessageQueue => state.messages;
export const { pushMessage, clearMessages } = messageSlice.actions;

export default messageSlice.reducer;