import { baseApi } from '@/store/api/ignion/_base'
import { GetProjectsRequest, GetProjectRequest, GetProjectSummary, AddProjectRequest, UpdateProjectRequest, UpdateContactProjectRequest, AddFileRequest, UpdateFileRequest, GetProjectFilesRequest } from '@/types/request/project'
import { AddProjectResponse, Project, ProjectSummary, UpdateProjectResponse, AddFileResponse, UpdateFileResponse, ProjectFile } from '@/types/response/project'
import { Page } from '@/types/response/types'
import { buildQueryParamsString } from '@/lib/utils'

export const projectApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProjects: builder.query<Page<Project>, GetProjectsRequest>({
      query: (params) => { 
        let url = `/organization/${params.orgId}/project`;
        let queryParams = buildQueryParamsString(params, ["orgId"]);
        return ({ url: url.concat(queryParams) })
      },
    }),
    getProject: builder.query<Project, GetProjectRequest>({
      query: ({ idType, orgId, projectId }) => ({
        /** Generates 
         *    ​/organization​/ORG_XXX/project/PROJ_123?id_type=0
         *    ​/organization​/ORG_xxx/project/PROJ_123?id_type=0&id_type=1
         **/
        url: `/organization/${orgId}/project/${projectId}?`.concat(idType.reduce((acc, curr) => acc + "&id_type=".concat(curr.toString()), "").slice(1)),
        method: 'GET'
      }),
      providesTags: ['Project']
    }),
    addProject: builder.mutation<AddProjectResponse, AddProjectRequest>({
      query: ({ orgId, body }) => ({
        url: `organization/${orgId}/project`,
        method: 'POST',
        body: body
      }),
    }),
    getProjectSummary: builder.query<ProjectSummary, GetProjectSummary>({
      query: (params) => { 
        let url = `/organization/${params.orgId}/project/summary`;
        let queryParams = buildQueryParamsString(params, ["orgId"]);
        return ({ url: url.concat(queryParams) })
      },
      providesTags: ['ProjectSummary']
    }),
    updateProject: builder.mutation<UpdateProjectResponse, UpdateProjectRequest>({
      query: ({ orgId, projectId, body }) => ({
        url: `organization/${orgId}/project/${projectId}`,
        method: 'PATCH',
        body: body
      }),
      invalidatesTags: ['Project', 'ProjectSummary']
    }),
    updateContactsProject: builder.mutation<UpdateProjectResponse, UpdateContactProjectRequest>({
      query: ({ orgId, projectId, body }) => ({
        url: `organization/${orgId}/project/${projectId}`,
        method: 'PATCH',
        body: body
      }),
      invalidatesTags: ['Project']
    }),
    addFile: builder.mutation<AddFileResponse, AddFileRequest>({
      query: ({ orgId, projectId, body }) => ({
        url: `organization/${orgId}/project/${projectId}/file`,
        method: 'POST',
        body: body
      }),
    }),
    updateFile: builder.mutation<UpdateFileResponse, UpdateFileRequest>({
      query: ({ orgId, projectId, body }) => ({
        url: `organization/${orgId}/project/${projectId}/file/status`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['ProjectFiles']   
    }),
    getProjectFiles: builder.query<ProjectFile[], GetProjectFilesRequest>({
      query: ({ orgId, projectId }) => ({
        url: `organization/${orgId}/project/${projectId}/files`,
        method: 'GET'
      }),
      providesTags: ['ProjectFiles']
    }),
    deleteProject: builder.mutation<UpdateProjectResponse, GetProjectFilesRequest>({
      query: ({ orgId, projectId }) => ({
        url: `organization/${orgId}/project/${projectId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Project', 'ProjectSummary']
    }),
  })
})

export const {
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  
  useGetProjectQuery,
  useLazyGetProjectQuery,

  useAddProjectMutation,
  
  useGetProjectSummaryQuery,
  useLazyGetProjectSummaryQuery,

  useUpdateProjectMutation,
  useUpdateContactsProjectMutation,
  useAddFileMutation,
  useUpdateFileMutation,
  useGetProjectFilesQuery,
  useLazyGetProjectFilesQuery,

  useDeleteProjectMutation
} = projectApi
