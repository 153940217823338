import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE } from "next-redux-wrapper";
import { Auth } from 'aws-amplify';
import { initialState, AsyncStatus } from "@/types/user";
import { pushMessage } from "@/store/slice/messages";
import { ToastMessage } from "primereact/toast";

interface ChangePasswordData {
  newPassword: string
  oldPassword: string
}

/** Known cognito user attributes */
export interface CognitoAttributes {
  [key: string]: string;
}

export const setUser = createAsyncThunk('setUser', async (newState: CognitoAttributes, { rejectWithValue, fulfillWithValue, dispatch }) => {

  await Auth.currentAuthenticatedUser()
    .then(user => {
      Auth.updateUserAttributes(user, newState);
      return fulfillWithValue(newState);
    })
    .catch(e => {
      let error = {
        severity: "error",
      } as ToastMessage;
      return rejectWithValue(error);
    });
})

export const changePassword = createAsyncThunk('changePassword', async (password: ChangePasswordData, { rejectWithValue, dispatch }) => {

  await Auth.currentAuthenticatedUser()
    .then(user => Auth.changePassword(user, password.oldPassword, password.newPassword))
    .catch(e => {
      let error = {
        severity: "error",
        summary: e.name,
        detail: e.message,
        life: 3000,
      } as ToastMessage;
      rejectWithValue(error);
      dispatch(pushMessage(error));
    });
})

// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //setUSer
      .addCase(setUser.pending, (state) => {
        state.status = AsyncStatus.LOADING
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.status = AsyncStatus.IDLE
      })
      .addCase(setUser.rejected, (state) => {
        state.status = AsyncStatus.ERROR;
      })
      //changePassword
      .addCase(changePassword.pending, (state) => {
        state.status = AsyncStatus.LOADING
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = AsyncStatus.IDLE
      })
      .addCase(changePassword.rejected, (state) => {
        state.status = AsyncStatus.ERROR;
      })
      //hydrate      
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          ...action,
        };
      })
  }
})

export default userSlice.reducer;