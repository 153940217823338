import { addDaysToDate } from "@/lib/date"
import { useSetInteractionMutation } from "@/store/api/ignion/userInteractions"
import { userInteractionsType } from "@/types/response/userInteractions"
import { useLocalStorage } from "./useLocalStorage"
import { useAppDispatch } from "@/store/hooks"
import { setTriggerAction } from "@/store/slice/featureFlags"
import { pushMessage } from "@/store/slice/messages"

export const useUserInteraction = () => {
    
    const [setInteraction] = useSetInteractionMutation()
    const { setItemUnderKey, getItemUnderKey } = useLocalStorage('OxionStorage')

    const dispatch = useAppDispatch()
    
    const daysToAddNPS_Initial = 3
    const daysToAddNPS_Later = 15

    const setBooleanInteraction = (category: string) => {

        const body = { category, count: 1, value: "true" }
        setInteraction({ body })

        setItemUnderKey(category, true)
    }

    const generateNPSSurvey = () => {
        const askTime = addDaysToDate(daysToAddNPS_Initial)
        const bodyNPS = { score: -1, comment: '', askAgain: askTime }
        const body = { category: userInteractionsType.NPS_SURVEY, count: 0, value: JSON.stringify(bodyNPS) }
        setItemUnderKey(userInteractionsType.NPS_SURVEY, bodyNPS)
        setInteraction({ body })
    }

    const checkNPSVisbility = () => {
        const NPS_SURVEY = getItemUnderKey(userInteractionsType.NPS_SURVEY)

        if (NPS_SURVEY) {
            const dateToAsk = NPS_SURVEY.askAgain
            const now = Date.now()

            if (dateToAsk < now && NPS_SURVEY.score < 0) {
                setTimeout( () => {
                dispatch(setTriggerAction({ feature: 'NPS_SURVEY', value: true }));
                }, 60000);
                
            } 
        }
    }
    
    const askmeLaterNPS = () => {
        const askTime = addDaysToDate(daysToAddNPS_Later)
        const bodyNPS = { score: -1, comment: '', askAgain: askTime }

        const body = { category: userInteractionsType.NPS_SURVEY, value: JSON.stringify(bodyNPS) }
        setItemUnderKey(userInteractionsType.NPS_SURVEY, bodyNPS)
        setInteraction({ body })
        
        dispatch(setTriggerAction({ feature: 'NPS_SURVEY', value: false }));
    }

    const voteNPS = (score: number, comment: string) => {
        const now = Date.now()
        const bodyNPS = { score, comment, askAgain: now }

        const body = { category: userInteractionsType.NPS_SURVEY, value: JSON.stringify(bodyNPS) }
        setItemUnderKey(userInteractionsType.NPS_SURVEY, bodyNPS)
        setInteraction({ body }).unwrap().then((res) => {
            dispatch(pushMessage({
                severity: 'success',
                summary: 'THANKS_FEEDBACK'
            }));
        });
    }

    return { setBooleanInteraction, generateNPSSurvey, checkNPSVisbility, askmeLaterNPS, voteNPS }
}