import { Message, ReleaseNoteNotificationMessageData } from "@/types/response/messages";
import NotificationMessage from "../NotificationMessage";
import { useI18nContext } from "@/i18n/i18n-react";
import { useSetMessageReadMutation } from "@/store/api/ignion/message";
import LinkButton from "@/components/common/LinkButton";
import { useContext } from "react";
import { LayoutContext } from "@/layout/context/layoutcontext";

type ReleaseNoteNotificationProps = {
  notification: Message<ReleaseNoteNotificationMessageData>;
};

export default function ReleaseNoteNotification({ notification }: Readonly<ReleaseNoteNotificationProps>) {
  const { LL } = useI18nContext();

  const [setMessageRead] = useSetMessageReadMutation();
  const { setNotificationPopupToggle } = useContext(LayoutContext);

  return (
    <NotificationMessage notification={notification}>
      <div className="notification-text release-note">
        <LinkButton label={LL.message.notification.releaseNotes.name({version: notification.data.version})} handleClick={() => {
          setNotificationPopupToggle(true);
          setMessageRead({ messageId: notification.id });
        }} /> {LL.message.notification.releaseNotes.description()}
      </div>
    </NotificationMessage>
  )
}