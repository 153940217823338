import {  EstimationAntennaCurve, EstimationCurveCoordinate } from '@/types/response/estimation'
import { NNS10BoMService, NNS10Service } from '@/types/response/service';
import { ChartOptions } from "chart.js";
import { isValidEmail } from './validation';

/**
 * Evaluates a command and returns the result or a default value if the command is not valid or if result is undefined o an empty string
 * @param command 
 * @param defaultValue 
 * @returns 
 */
export function evalOrDefault(fn : () => string, defaultValue:any) {  
  try {
    let result = fn();
    if (result === null || result === undefined || result === "") return defaultValue;
    else return result;
  } catch (e) {
    return defaultValue;
  }
}

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName]; // o[propertyName] is of type T[K]
}

export const extractGivenNameAndSurnameFromEmail = (email:string):string => {
  let name:string = '';
  let nameParts: string[] = [];
  if (isValidEmail(email)) {
     name = email.split('@')[0];
     nameParts = name.split('.');
     return nameParts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  }else{
    return '';
  }
  
  
}

export const buildQueryParamsString = (params: {[key:string]:any}, exclude:string[] = [], prefix:string = "?") => {
  if (params === undefined || !params) return "";
  let query = Object.keys(params)
    .filter((key) => !exclude.includes(key))
    .filter((key) => params[key] !== undefined && params[key] !== null && params[key] !== "")
    .reduce((acc, curr) => {
      if (Array.isArray(params[curr])) {
        const currArray = params[curr].reduce((acc2:any, val:any) => `${acc2}&${curr}=${val}`, "")
        return `${acc}${currArray}`;
      } else {
        return `${acc}&${curr}=${params[curr]}`;
      }
    }, "").slice(1);
  return query.length > 0 ? prefix.concat(query) : "";
}

export const fromCoordinatesToChartData = (coordinates: EstimationCurveCoordinate[], name:string, color: string, yAxisID: string = "y", extraOptions: any = {}) => {
  return {
    data: coordinates,
    fill: false,
    backgroundColor: color,
    borderColor: color,
    tension: 0.4,
    yAxisID: yAxisID,
    label: name,
    borderWidth: 1.5,
    ...extraOptions
  }
}

type estimationChartOptions = {
  efficiencyName?: string,
  efficiencyColor?: string,
  efficiencyExtraOptions?: any,
  s11Name?: string,
  s11Color?: string,
  s11ExtraOptions?: any
}

export const fromEstimationAntennaCurveToChartData = (data:EstimationAntennaCurve, options?:estimationChartOptions) => {
  if (data.efficiency_curve !== undefined && data.s11_curve != undefined){
    return {
      datasets: [
        fromCoordinatesToChartData(data.efficiency_curve.coordinates, options?.efficiencyName ?? "Total Efficiency (%)", options?.efficiencyColor ?? "#FC3344", "y", options?.efficiencyExtraOptions),
        fromCoordinatesToChartData(data.s11_curve.coordinates, options?.s11Name ?? "S11 (dB)", options?.s11Color ?? "#71B976", "y1", options?.s11ExtraOptions)
      ],
    }
  } else return []
}

const efficiencyBasicOptions:ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0
  },
  elements: {
    point:{
      borderWidth: 0,
      radius: 0
    }
  },
  plugins: {
    legend: {      
      position: "bottom",
      labels: {
        color: '#1e293b',
        boxHeight: 0
      },
    },
  }
}

export const efficiencyChartOptions:ChartOptions = {
  ...efficiencyBasicOptions,
  scales: {
    x: {
      type: 'linear',
      ticks: {
        color: '#64748b',
      },
      title:{
        display: true,
        text: 'Frequency (GHz)',
      },
      grid: {
        color: '#dfe7ef',
      },
      border: {
        display: false,
      },
    },
    y: {
      type: 'linear',
      min: 0,
      max: 100,
      title:{
        display: true,
        text: 'Total Efficiency (%)',
      },
      ticks: {
        stepSize: 10,
        color: '#64748b',
      },
      grid: {
        color: '#dfe7ef',
      },
      border: {
        display: false,
      },
    },
    y1: {
      type: 'linear',
      position: 'right',
      max: 0,
      min: -20,
      ticks: {
        stepSize: 2,
      },
      title: {
        display: true,
        text: 'S11 (dB)',
      },
      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    }
  },
};

export const efficiencyChartOptionsLow20:ChartOptions = {
  ...efficiencyBasicOptions,
  scales: {
    x: {
      type: 'linear',
      ticks: {
        color: '#64748b',
      },
      title:{
        display: true,
        text: 'Frequency (GHz)',
      },
      grid: {
        color: '#dfe7ef',
      },
      border: {
        display: false,
      },
    },
    y: {
      type: 'linear',
      min: 0,
      max: 100,
      title:{
        display: true,
        text: 'Total Efficiency (%)',
      },
      ticks: {
        stepSize: 10,
        color: '#64748b',
      },
      grid: {
        color: '#dfe7ef',
      },
      border: {
        display: false,
      },
    },
    y1: {
      type: 'linear',
      position: 'right',
      max: 0,
      min: -40,
      ticks: {
        stepSize: 4,
      },
      title: {
        display: true,
        text: 'S11 (dB)',
      },
      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    }
  },
};

export const navigateToExternalUrl = (url: string, shouldOpenNewTab: boolean = true) => {
  if (shouldOpenNewTab) window.open(url, "_blank") 
  else window.location.href = url;
}

export const labelSimulation = (service:NNS10Service | NNS10BoMService, labelSuggested:string = 'Suggested') => {
  return service.preferred ? service.name : `${service.name} - ${labelSuggested}`
}

export const formatDateToString = (date: Date) => {
  let dateIso = date.toISOString();
  return dateIso.substring(0, dateIso.lastIndexOf('.'));
}

export const blockScroll = () => {
  const body = document.querySelector('body');
  if (body) {
      body.style.overflow = 'hidden';
  }
}

export const unBlockScroll = () => {
  const body = document.querySelector('body');
  if (body) {
      body.style.overflow = 'auto';
      body.style.overflowX = 'hidden'
  }
}

export const slideToComponent = (componentId: string) => {
  const component = document.querySelector(componentId);
  if (component) {
    component.scrollIntoView({behavior: "smooth"});
  }
}