export const useLocalStorage = (key: string) => {
    
    const setItem = (value: unknown) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            console.log(error)
        }
    }

    const setItemUnderKey = (innerKey: string, value: unknown) => {
        try {
            const initialValue = getItem()
            const newValue = {...initialValue, [innerKey]: value}
            window.localStorage.setItem(key, JSON.stringify(newValue))
        } catch (error) {
            console.log(error)
        }
    }

    const getItem = () => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : undefined
        } catch (error) {
            console.log(error)
        }
    }

    const getItemUnderKey = (innerKey: string) => {
        try {
            const item = window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key) ?? '') : undefined
            return item ? item[innerKey] : undefined
        } catch (error) {
            console.log(error)
        }
    }

    const removeItem = () => {
        try {
            window.localStorage.removeItem(key)
        } catch (error) {
            console.log(error)
        }
    }

    return { setItem, setItemUnderKey, getItem, getItemUnderKey, removeItem }
}