import { FC, useState } from 'react'
import { MainButton } from '@/components/common/MainButton'
import { RadioButtonIcon } from '@/components/common/RadioButtonIcon'
import { useI18nContext } from '@/i18n/i18n-react'
import { styleMainButton } from '@/types/common'
import { InputTextarea } from 'primereact/inputtextarea'
import { IconButton } from '@/components/common/IconButton'
import { IconClose } from '@/components/common/IconButton/consts'
import { useUserInteraction } from '@/hooks/useUserInteraction'

interface NpsProps {
    hideModal: () => void
}

const Nps: FC<NpsProps> = ({ hideModal }) => { 
    const { LL } = useI18nContext()

    const { askmeLaterNPS, voteNPS } = useUserInteraction()

    const [puntuation, setPuntuation] = useState<number>(-1)
    const [message, setMessage] = useState<string>('')
    
    const renderVotes = () => {
        let components = [];
        for (let index = 0; index <= 10; index++) {
            components.push(<div key={`npsVote-${index}`} className="npsVote">
                <RadioButtonIcon active={puntuation === index} handleClick={() => { setPuntuation(index) }} />
                <span className='Oxion-body-sBold'>{index}</span>
            </div>)
        }

        return components;
    }

    const askMeLater = () => {
        askmeLaterNPS()
        hideModal()
    }

    const handleVote = () => {
        voteNPS(puntuation, message)
        hideModal()
    }
    
    return (
        <>
          <IconButton handleClick={askMeLater}><IconClose /></IconButton>
          <h4 className='Oxion-bodyLarge-sBold'>{LL?.message.nps.title() ?? 'How are we doing so far?'}</h4>
          <span className='Oxion-small-medium'>{LL?.message.nps.recommend() ?? 'Would you recommend Oxion to your peers?'}</span>
          <div className='npsVotation'>
            { renderVotes() }
          </div>
          <span className='Oxion-small-medium'>{LL?.message.nps.comments() ?? 'Comments'}</span>
          <InputTextarea rows={5} className='w-full' value={message}
              onChange={(e) => setMessage(e.target.value)} placeholder={LL?.message.nps.placeholder() ?? 'We greatly value you taking a few seconds to give us feedback.'} />

          <div className="disclaimerOptions">
            <MainButton label={LL?.buttons.maybeLater() ?? 'Maybe later'} handleClick={askMeLater} style={styleMainButton.TERCIARY} />
            <MainButton label={LL?.buttons.submit() ?? 'Submit'} disabled={puntuation<0}  handleClick={handleVote} /> 
          </div>
        </>
    )
}


export default Nps