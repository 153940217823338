import { FC, SyntheticEvent } from "react"
import { Avatar } from "primereact/avatar";

export type UserTopBarButtonProps = {
    label: string
    onClick: (event: SyntheticEvent<Element, Event>) => void;
}


const UserTopBarButton: FC<UserTopBarButtonProps> = ({ label, onClick }) => {    
    return (
        <div className="user-topbar-button">
        <button onClick={(e) => onClick(e)}>
            <span>{ label }</span>
            <Avatar label={label.charAt(0)} shape="circle" size="normal" />
        </button>
        </div>       
    )
}


export default UserTopBarButton