import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "@/store/store";
import SwaggerParser from '@apidevtools/swagger-parser';

function createInitialState() {
    return {
        parsedJson: {}
    }
}

// create slice
const name = 'parsedJson';
const initialState = createInitialState();
export const getParsedJson = createAsyncThunk('getParsedJson',
    async () => {
        let infoApi = {};
        try {
            // dereference the document into definition
            infoApi = await SwaggerParser.dereference(process.env.NEXT_PUBLIC_VALIDATION_RULES_URL ?? "/validation-rules.json");
        } catch (err) {
            // just emit a warning about the validation errors
            console.warn(err);
        }
        return infoApi
    }
);

export const parserSlice = createSlice({
    name: 'parsedJson',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getParsedJson.pending, (state, action) => {
            })
            .addCase(getParsedJson.fulfilled, (state, action) => {
                state.parsedJson = action.payload
            })
            .addCase(HYDRATE, (state, action) => {
                return {
                    ...state,
                    ...action,
                };
            })
    }
});

// exports

export const selectParserState = (state: AppState) => state.parsedJson;

export default parserSlice.reducer;