import NotificationFactory from "@/lib/notificationfactory/Factory";
import { MessageType } from "@/types/response/messages";
import LinkButton from "../common/LinkButton";
import { useGetAllMessagesByQuery, useSetMessageReadMutation } from "@/store/api/ignion/message";
import { useI18nContext } from "@/i18n/i18n-react";

type NotificationsProps = {
  onClose: () => void;
};

export default function Notifications({ onClose }: Readonly<NotificationsProps>) {
  const { LL } = useI18nContext();

  const limitNotifications:number = 5

  const [setMessageRead] = useSetMessageReadMutation();
  const messages = useGetAllMessagesByQuery({type: MessageType.NOTIFICATION, limit: limitNotifications});

  const factory = new NotificationFactory();

  const markAllAsRead = () => {
    messages.data?.items.forEach((notification) => {
      setMessageRead({messageId: notification.id});
    });
    onClose();
  }

  return (
    <div className="notifications">
      <div className="notifications-header">
        <LinkButton label={LL.menu.top.markAllAsRead()} icon="ms ms-check-circle" iconPosition="right" handleClick={() => markAllAsRead()} disabled={messages.data?.items.filter(item => !item.read).length === 0} />
      </div>
      <div className="notifications-content">
        {messages.data !== undefined && messages.data.items.length > 0 ? messages.data.items.slice(0,limitNotifications).map((notification, index) => factory.create(notification)) :
          <div className="Oxion-body-regular notifications-empty">{LL.menu.top.noNotifications()}</div> }
      </div>
    </div>  
  )
}