import { SetInteractionRequest, UserInteractions } from "@/types/response/userInteractions";
import { baseApi } from "./_base"

export const interactionsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getAllInteractions: builder.query<UserInteractions[], void>({
            query: () => ({
                url: 'interaction',
                method: 'GET'
            }),
            providesTags: ['Interactions'],
        }),

        setInteraction: builder.mutation<void, SetInteractionRequest>({
            query: ({ body }) => ({
                url: `interaction`,
                method: 'PUT',
                body: body
              }),
            invalidatesTags: ['Interactions']
        }),

        deleteInteraction: builder.mutation<void, { category: string }>({
            query: ({ category }) => ({
                url: `interaction/${category}`,
                method: 'DELETE',
              }),
            invalidatesTags: ['Interactions']
        }),
    })
  })
  
  export const {
    useGetAllInteractionsQuery,
    useLazyGetAllInteractionsQuery,
    useSetInteractionMutation,
    useDeleteInteractionMutation
  } = interactionsApi