import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "@/store/store";

const version = process.env.NEXT_PUBLIC_VERSION ?? "0.0.1";

export const versionSlice = createSlice({
  name: 'version',
  initialState: {
    v: version,
  },
  reducers: {
    clearStore() {
      // Note that this should be left intentionally empty.
    },
  },
  extraReducers: builder => {
    builder
        .addCase(HYDRATE, (state, action) => {
            return {
                ...state,
                ...action,
            };
        })
}
})

export const selectCurrentVersion = (state: AppState) => state.version.v;

export default versionSlice.reducer;
export const { clearStore } = versionSlice.actions