import { Message, ServiceNotificationMessageData } from "@/types/response/messages";
import { useI18nContext } from "@/i18n/i18n-react";
import { useRouter } from "next/router";
import { useSetMessageReadMutation } from "@/store/api/ignion/message";
import NotificationMessage from "../NotificationMessage";
import LinkButton from "@/components/common/LinkButton";

type ErrorNotificationProps = {
  notification: Message<ServiceNotificationMessageData>;
};

export default function ErrorNotification({ notification }: Readonly<ErrorNotificationProps>) {
  const router = useRouter();
  const { LL } = useI18nContext();

  const [setMessageRead] = useSetMessageReadMutation();

  return (
    <NotificationMessage notification={notification}>
      <div className="notification-text not-feasible">
        {LL.message.notification.error.msg()}
        <LinkButton label={LL.message.notification.error.link()} handleClick={() => {
          setMessageRead({ messageId: notification.id });
          router.push(`/organization/${notification.data.orgId}/project/${notification.data.projectId}?serviceId=${notification.data.entityId}`, undefined, { shallow: true })}} />
      </div>
    </NotificationMessage>
  )
}