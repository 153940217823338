import ErrorNotification from "@/components/message/notifications/ErrorNotification";
import NotFeasibleNotification from "@/components/message/notifications/NotFeasibleNotification";
import NotificationMessage from "@/components/message/NotificationMessage";
import ProjectSharedNotification from "@/components/message/notifications/ProjectSharedNotification";
import ProjectUpdatedNotification from "@/components/message/notifications/ProjectUpdatedNotification";
import ServiceDenyNotification from "@/components/message/notifications/ServiceDenyNotification";
import ServiceDoneNotification from "@/components/message/notifications/ServiceDoneNotification";
import WelcomeNotification from "@/components/message/notifications/WelcomeNotification";
import { EntityNotificationMessageData, Message, MessageData, MessageType, NotificationMessageData, NotificationType, ReleaseNoteNotificationMessageData, ServiceNotificationMessageData } from "@/types/response/messages";
import { Nullable } from "primereact/ts-helpers";
import ReleaseNoteNotification from "@/components/message/notifications/ReleaseNoteNotification";

class NotificationFactory {

  create(message:Message<MessageData>): Nullable<JSX.Element> {
    if (message.type === MessageType.NOTIFICATION) {
      return this.createNotification(message);
    }

    return null;    
  }

  createNotification(message: Message<MessageData>): Nullable<JSX.Element> {
    let notification = message as Message<NotificationMessageData>;

    switch (notification.data?.type) {
      case NotificationType.WELCOME:
        return <WelcomeNotification key={message.id} notification={notification} />
      
      case NotificationType.SERVICE_DONE:
        return <ServiceDoneNotification key={message.id} notification={notification as Message<ServiceNotificationMessageData>} />
      
      case NotificationType.SERVICE_DENY:
        return <ServiceDenyNotification key={message.id} notification={notification as Message<ServiceNotificationMessageData>} />  
      
      case NotificationType.PROJECT_UPDATED:
        return <ProjectUpdatedNotification key={message.id} notification={notification as Message<EntityNotificationMessageData>} />
      
      case NotificationType.PROJECT_SHARED:
          return <ProjectSharedNotification key={message.id} notification={notification as Message<EntityNotificationMessageData>} />
        
      case NotificationType.ERROR:
            return <ErrorNotification key={message.id} notification={notification as Message<ServiceNotificationMessageData>} />
        
      case NotificationType.NOT_FEASIBLE:
          return <NotFeasibleNotification key={message.id} notification={notification as Message<ServiceNotificationMessageData>} />
      
      case NotificationType.RELEASE_NOTE:
          return <ReleaseNoteNotification key={message.id} notification={notification as Message<ReleaseNoteNotificationMessageData>} />
      
      default:
        return (
          <NotificationMessage notification={notification}>
            <div className="notification-text">{JSON.stringify(notification.data)}</div>
          </NotificationMessage>
        )
    }
  }
}

export default NotificationFactory;