import React , { ReactNode } from 'react'
import { Tooltip } from 'primereact/tooltip';
import { ValidationError } from '@/lib/validation';
import { useI18nContext } from '@/i18n/i18n-react';
import { evalOrDefault } from '@/lib/utils';
import { clsx } from 'clsx';

type InputContainerProps = {
  className?: string
  labelText?: string
  children?: ReactNode
  toolTipText?: string
  id: string
  inputHelpText?: string
  error?: ValidationError
  hideError?: boolean
}

export default function InputContainer({className, labelText, children, toolTipText, id, inputHelpText, hideError = false, error = {error:false, msg:''}}: Readonly<InputContainerProps>) {
  const { LL } = useI18nContext();

  let msg = '';
  if (error?.error) { 
    msg = evalOrDefault(() => eval('LL.error["' + error.msg + '"]()'), error.msg);
  } else if (inputHelpText !== undefined) {
    msg = inputHelpText;
  }
 
  return (
    <div className={clsx(`flex flex-column gap-2 w-full input-container`, className)} >
        {labelText !== undefined ? <label className={error.error ? "error" : ""} htmlFor={id}> {labelText} </label> : null}
        
        {toolTipText !== undefined ? 
          <i className="details-username ml-1 ms ms-info" style={{ fontSize: '0.8rem', cursor: 'pointer' }}>    
            <Tooltip target=".details-username" data-pr-position="right" data-pr-at="right">{toolTipText}</Tooltip> 
          </i> : null}  
      {React.Children.map(children, child =>
        // @ts-ignore
        React.cloneElement(child, {
          // @ts-ignore
          className: error.error ? `${child.props.className} p-invalid` : child.props.className
        })
      )}

      {
        hideError ? null :
          <small className={error.error ? 'input-container-error error' : "input-container-help"} >{msg}</small>
      }
      
    </div>
  );
} 